<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="E-mail" :subtitle="$t('sms.usage-report.header')" />
    <div class="page-content container-fluid">
      <div class="card card-tabs flex-row">
        <div class="painel-stat" :class="{ 'qf-visible': isStatistics }">
          <div class="card-header">
            <div class="full">
              <div class="row">
                <div class="col-sm-8">
                  <ul class="nav nav-tabs">
                    <li class="nav-item" role="presentation">
                      <router-link to="/mail/reports/stat" class="nav-link" replace>
                        <span class="material-symbols-outlined">insert_chart</span>
                        <span>{{ $tc('app.report', 1) }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <router-link to="/mail/reports/history" class="nav-link" replace>
                        <span class="material-symbols-outlined">history</span>
                        <span>{{ $t('app.history') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <router-link to="/mail/usage" class="nav-link" replace>
                        <span class="material-symbols-outlined">insert_chart</span>
                        <span>Gastos</span>
                      </router-link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <router-link to="/mail/campaigns" class="nav-link" replace>
                        <span class="material-symbols-outlined">campaign</span>
                        <span>Campanhas</span>
                      </router-link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <router-link :to="{ name: 'MailReportExports' }" class="nav-link" replace
                        ><span class="material-symbols-outlined"> cloud_download </span><span>{{ $tc('app.file', 2) }}</span></router-link
                      >
                    </li>
                    <!-- div class="opts_card">
                      <a style="display: flex; align-items: center" href="javascript:void(0)" @click="reload">
                        <span class="material-symbols-outlined title">refresh</span>
                      </a>
                      <form class="form-inline calendar_form">
                        <date-range-picker
                          class="form-control"
                          :format="'YYYY-MM-DD'"
                          :options="options"
                          @change="changeDate"
                        />
                      </form>
                      <span class="material-icons title calendar_icon">calendar_today</span>
                      <i class="fas fa-file-export title"></i>
                    </div -->
                  </ul>
                </div>
                <div class="col-sm-4">
                  <ul class="nav-actions d-flex flex-row-reverse align-items-center">
                    <li class="quick-filters-open" v-if="isStatistics">
                      <button class="btn btn-light" @click="quickFilterVisible = true">
                        <span class="material-icons-outlined title">menu</span>
                      </button>
                    </li>
                    <li>
                      <b-dropdown variant="light" :right="true" no-caret>
                        <template #button-content>
                          <span class="material-icons-outlined title">file_download</span>
                        </template>
                        <b-dropdown-item @click="exportData('csv')">CSV</b-dropdown-item>
                        <b-dropdown-item @click="exportData('png')">PNG</b-dropdown-item>
                        <b-dropdown-item @click="exportData('svg')">SVG</b-dropdown-item>
                      </b-dropdown>
                    </li>
                    <li>
                      <button class="btn btn-light" @click="refresh()">
                        <span class="material-icons-outlined title">refresh</span>
                      </button>
                    </li>
                    <li>
                      <button class="btn btn-light calendar">
                        <span class="material-icons-outlined title">calendar_today</span>
                        <date-range-picker class="form-control" :options="calendarOpts" @change="changeDate" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body block-el">
            <router-view ref="view" :date-gte="dateGte" :date-lte="dateLte" />
          </div>
        </div>
        <div
          class="quick-filters"
          :class="{
            'd-none': !isStatistics,
            show: quickFilterVisible,
          }"
        >
          <!-- status -->
          <div class="qf-item" :class="{ active: quickFilterActive === 'status' }">
            <div class="qf-timeline">
              <div class="qf-icon badge bg-secondary">
                <span class="material-icons-outlined">verified</span>
              </div>
              <hr />
            </div>
            <div class="qf-loading d-flex align-items-center justify-content-center" v-if="loadingEvents">
              <LoadingAnim />
            </div>
            <div v-else>
              <h4>Informação por status</h4>
              <p>Total</p>
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style="width: 45%">{{ viewEvents | humanSize }}</div>
              </div>
              <footer>
                <h5 class="text-center">Processo estimado</h5>
                <p class="text-center">{{ humanRangeDate }}</p>
              </footer>
              <button class="details btn btn-light" @click="chooseChart('status')">
                Ver Detalhes
                <span class="material-icons-outlined">east</span>
              </button>
            </div>
          </div>

          <!-- eventos -->
          <div class="qf-item" :class="{ active: quickFilterActive === 'events' }">
            <div class="qf-timeline">
              <div class="qf-icon badge bg-secondary">
                <span class="material-icons-outlined">ads_click</span>
              </div>
              <hr />
            </div>
            <div class="qf-loading d-flex align-items-center justify-content-center" v-if="loadingEvents">
              <LoadingAnim />
            </div>
            <div v-else>
              <h4>Informação por Eventos</h4>
              <p>Total</p>
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style="width: 45%">{{ viewEvents | humanSize }}</div>
              </div>
              <footer>
                <h5 class="text-center">Processo estimado</h5>
                <p class="text-center">{{ humanRangeDate }}</p>
              </footer>
              <button class="details btn btn-light" @click="chooseChart('events')">
                Ver Detalhes
                <span class="material-icons-outlined">east</span>
              </button>
            </div>
          </div>

          <!-- dispositivos -->
          <div class="qf-item" :class="{ active: quickFilterActive === 'devices' }">
            <div class="qf-timeline">
              <div class="qf-icon badge bg-secondary">
                <span class="material-icons-outlined">devices</span>
              </div>
              <hr />
            </div>
            <div class="qf-loading d-flex align-items-center justify-content-center" v-if="loadingDevices">
              <LoadingAnim />
            </div>
            <div v-else>
              <h4>Informação por Dipositivos</h4>
              <p>Webmail</p>
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style="width: 45%">{{ viewDevices | humanSize }}</div>
              </div>
              <footer>
                <h5 class="text-center">Processo estimado</h5>
                <p class="text-center">{{ humanRangeDate }}</p>
              </footer>
              <button class="details btn btn-light" @click="chooseChart('devices')">
                Ver Detalhes
                <span class="material-icons-outlined">east</span>
              </button>
            </div>
          </div>

          <!-- provedor -->
          <div class="qf-item" :class="{ active: quickFilterActive === 'provider' }">
            <div class="qf-timeline">
              <div class="qf-icon badge bg-secondary">
                <span class="material-icons-outlined">web</span>
              </div>
              <hr />
            </div>
            <div class="qf-loading d-flex align-items-center justify-content-center" v-if="loadingProviders">
              <LoadingAnim />
            </div>
            <div v-else>
              <h4>Informação por Provedor</h4>
              <p>Windows</p>
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style="width: 45%">{{ viewProviders | humanSize }}</div>
              </div>
              <footer>
                <h5 class="text-center">Processo estimado</h5>
                <p class="text-center">{{ humanRangeDate }}</p>
              </footer>
              <button class="details btn btn-light" @click="chooseChart('providers')">
                Ver Detalhes
                <span class="material-icons-outlined">east</span>
              </button>
            </div>
          </div>

          <!-- navegador -->
          <div class="qf-item" :class="{ active: quickFilterActive === 'browser' }">
            <div class="qf-timeline">
              <div class="qf-icon badge bg-secondary">
                <span class="material-icons-outlined">open_in_browser</span>
              </div>
              <hr />
            </div>
            <div class="qf-loading d-flex align-items-center justify-content-center" v-if="loadingEvents">
              <LoadingAnim />
            </div>
            <div v-else>
              <h4>Informação por Navegador</h4>
              <p>Chrome</p>
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style="width: 45%">{{ viewBrowsers | humanSize }}</div>
              </div>
              <footer>
                <h5 class="text-center">Processo estimado</h5>
                <p class="text-center">{{ humanRangeDate }}</p>
              </footer>
              <button class="details btn btn-light" @click="chooseChart('browsers')">
                Ver Detalhes
                <span class="material-icons-outlined">east</span>
              </button>
            </div>
          </div>

          <header class="p-2">
            <button class="btn btn-light" @click="quickFilterVisible = false">
              <span class="material-icons-outlined">close</span>
            </button>
          </header>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'OmniReports',
  components: {
    // Card,
    PageHeader,
    LoadingAnim,
  },
  watch: {
    $route(to, from) {
      this.$nextTick(() => console.log('$route:://', this.$refs.view));
    },
  },
  computed: {
    isStatistics() {
      return this.$route.path.indexOf('/stat') !== -1;
    },

    view() {
      return this.$refs?.view || {};
    },

    viewStatus() {
      // dataSentMessages
      // dataMessagesEvents
      // dataMessagesProviders
      // dataMessagesDevices
      // dataMessagesBrowsers
      const items = this.dataSentMessages;

      return [this.loadingEvents, items];
    },

    loadingEvents() {
      this.localTick;
      return this.$refs?.view?.loadingEvents ?? true;
    },
    loadingProviders() {
      this.localTick;
      return this.$refs?.view?.loadingProviders ?? true;
    },
    loadingDevices() {
      this.localTick;
      return this.$refs?.view?.loadingDevices ?? true;
    },
    loadingBrowsers() {
      this.localTick;
      return this.$refs?.view?.loadingBrowsers ?? true;
    },

    viewEvents() {
      this.localTick;

      const items = this.$refs?.view?.dataMessagesEvents ?? [];

      return items.reduce((a, item) => {
        const sum = item?.values?.reduce((b, value) => b + value?.[1] ?? 0, 0);
        return a + sum;
      }, 0);
    },
    viewProviders() {
      this.localTick;

      const items = this.$refs?.view?.dataMessagesProviders ?? [];

      return items.reduce((a, item) => {
        const sum = item?.values?.reduce((b, value) => b + value?.[1] ?? 0, 0);
        return a + sum;
      }, 0);
    },
    viewDevices() {
      this.localTick;

      const items = this.$refs?.view?.dataMessagesDevices ?? [];

      return items.reduce((a, item) => {
        const sum = item?.values?.reduce((b, value) => b + value?.[1] ?? 0, 0);
        return a + sum;
      }, 0);
    },
    viewBrowsers() {
      this.localTick;

      const items = this.$refs?.view?.dataMessagesBrowsers ?? [];

      return items.reduce((a, item) => {
        const sum = item?.values?.reduce((b, value) => b + value?.[1] ?? 0, 0);
        return a + sum;
      }, 0);
    },
    humanRangeDate() {
      const base = 86400000;
      const dateLte = this.dateLte.replace(/\s\d{1,2}[:]\d{1,2}[:]\d{1,2}$/, '');
      const dateGte = this.dateGte.replace(/\s\d{1,2}[:]\d{1,2}[:]\d{1,2}$/, '');

      if (dateLte === dateGte) return 'Hoje';

      const lte = new Date(this.dateLte);
      const gte = new Date(this.dateGte);

      let diff = Math.round((gte - lte) / base, 0);

      if (diff < 0) diff *= -1;

      if (diff === 1) return 'No útlimo dia';

      if (diff < 31) return `Útlimos ${diff} dias`;

      return `Útlimos ${Math.round(diff / 12, 0)} mese(s)`;
    },
  },
  data() {
    const today = moment();

    return {
      breadcrumbLinks: {
        1: '/mail/reports/stat',
        2: '/mail/reports/stat',
      },

      localTick: 0,
      quickFilterActive: 'status',
      quickFilterVisible: false,
      calendarOpts: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        },
        message: '',
      },
      dateGte: today.format('YYYY-MM-DD 00:00:00'),
      dateLte: today.format('YYYY-MM-DD 23:59:59'),
    };
  },
  filters: {
    humanSize: (value) => {
      if (!value) return 0;

      if (value < 1000) return value;

      let base = 1;
      let sulfix = '';

      if (value >= 1000000000) {
        base = 1000000000;
        sulfix = 'Bilhão(ões)';
      } else if (value >= 1000000) {
        base = 1000000;
        sulfix = 'Milhão(ões)';
      } else if (value >= 1000) {
        base = 1000;
        sulfix = 'Mil';
      }

      // return `${[value, base]} Mil`;
      return `${(value / base).toFixed()} ${sulfix}`;
    },
  },
  // Pai
  methods: {
    chooseChart(chart) {
      console.log('chart', chart);
      this.quickFilterVisible = false;
      this.quickFilterActive = chart;
      this.$refs.view.choose(chart);
    },
    refresh() {
      this.$refs.view?.fetch();
    },
    changeDate(date) {
      if (date) {
        console.log('changeDate', date);

        if (date?.length !== 2) {
          const today = moment().format('DD/MM/YYYY');
          date = [today, today];
        }

        this.dateGte = moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00');
        this.dateLte = moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD 23:59:59');
        this.refresh();
      }
    },
    exportData(format) {
      this.$refs.view.exportData(format);
    },
  },
};
</script>
<style lang="scss" scoped>
.painel-stat {
  width: 100% !important;

  &.qf-visible {
    width: calc(100% - 400px);
  }

  @media (max-width: 1200px) {
    width: 100% !important;
  }
}

.quick-filters-open {
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
}

.quick-filters {
  background-color: #fff;
  border-left: thin solid #ededed;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  min-width: 320px;
  position: relative;
  z-index: 10;

  @media (max-width: 1200px) {
    box-shadow: 0 -3px 15px rgba(0, 0, 0, 0.1);
  }

  & > header {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;
    display: none;

    .btn {
      padding: 0;
      height: 42px;
      width: 42px;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1200px) {
    display: none;
    overflow-x: hidden;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 62px;
    height: calc(100% - 62px);
    height: calc(100vh - 62px);

    & > header {
      display: block;
    }

    &.show {
      display: block;
    }
  }

  .qf-item {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0.4rem;
    height: 200px;
    position: relative;

    .details {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: var(--clr-yup-purple) !important;

      span {
        color: inherit;
        vertical-align: middle;
        font-size: 1.5em;
      }
    }

    p {
      margin-bottom: 0.1rem;
    }

    h4 {
      font-size: 0.82rem;
    }

    .qf-icon,
    hr {
      background: #ccc !important;
      transition: 0.1s ease-in;
    }

    &.active,
    &:hover {
      .qf-icon,
      hr {
        background: var(--clr-yup-purple) !important;
      }
    }

    .progress-bar {
      border-radius: 10px;
      padding-right: 0.72rem;
      text-align: right;
    }

    .qf-icon {
      color: #fff;
      font-size: 1.5rem;
      height: 40px;
      width: 40px;
    }

    .qf-loading {
      height: 100%;
    }

    .qf-timeline {
      width: 80px;
      height: 100%;
      float: left;

      & + div {
        width: calc(100% - 80px);
        float: right;
      }

      hr {
        position: absolute;
        left: 19px;
        top: 40px;
        border-radius: 10px;
        height: calc(100% - 75px);
        width: 4px;
      }
    }

    footer {
      margin-top: 0.5rem;
      font-size: 0.64em;

      h5 {
        font-weight: bold;
      }

      h5,
      p {
        font-size: 1em;
      }
    }
  }
}

.table-responsive {
  min-height: 150px;
}

.nav-actions {
  list-style-type: none;
  margin: 0;

  & > li {
    padding: 0.5rem;

    &:first-child {
      padding-right: 0;
    }

    &::v-deep {
      .btn {
        position: relative;
        font-size: 1.5rem;
        padding: 0.5rem;
        height: auto;
        display: flex;

        &.calendar {
          input {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
        }
      }

      .dropdown-menu {
        left: 0px !important;
      }
    }
  }
}

/* Rebranding */
.card.card-tabs .card-header {
  & > .full {
    width: 100%;
    padding: 0 1rem;
    gap: 2.5rem;
    margin-bottom: 5px;
    border-bottom: 2px solid rgba(210, 221, 234, 0.5);
  }

  .nav.nav-tabs {
    border-bottom: none;
    margin-bottom: 0;
    gap: 2.5rem;
  }
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}

/* Card-tabs mobile */
@media (max-width: 767.98px) {
  .card-tabs .nav-link span:last-of-type:not(.card-tabs .nav-link.router-link-active span) {
    display: none;
  }
  .card.card-tabs .card-header .nav.nav-tabs li a {
    transform: scale(1.1);
    height: 40px;
    padding: 20px 5px !important;
    padding-top: 30px !important;
  }
  .card.card-tabs .card-header .nav.nav-tabs {
    justify-content: space-between;
    gap: 5px !important;
  }
  .rebranding .card .opts_card {
    justify-content: flex-end;
    margin-left: unset !important;
    width: 100%;
    padding-top: 10px;
  }
  .rebranding .card .opts_card span {
    font-size: 26px;
    padding-top: 0;
  }
}
</style>
